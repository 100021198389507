import * as React from "react"
import { Link} from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { H2, H3 } from "../components/contentComponents/text"
import styled from "styled-components"
import ArticleItem from "../components/contentComponents/articleItem"
import linkArrowIcon from "../images/arrow-right.svg"
import arrowIcon from "../images/arrow.svg"

const BlogPage = (props) => {
  const t = props.pageContext.pageData
  const { site, locale, pageName, paging, category, fullPath } = props.pageContext
  const previousPage = paging.page - 1
  const nextPage = paging.page + 1

  const pageTitle = category ? t.pageTitle + '. Category: ' + category.name : t.pageTitle
  return (
    <Layout site={site} language={locale} pagePath={props.path} pageName={pageName}>
      <Seo title={pageTitle} lang={locale} pagePath={fullPath} site={site} meta={t.seo} />
      <TitleRow spaced={!!category}>
        <BlogTitle strong={true}>{t.pageTitle}</BlogTitle>
        {category && <AllPostsLink to={"/" + locale + "/blog/"}><span>{site.globals.readMoreSection.allPostsButtonTitle}</span><img src={linkArrowIcon} alt={""} /></AllPostsLink>}
      </TitleRow>
      {category && <CategoryTitle strong={false}>Category: {category.name}</CategoryTitle>}
      <ArticleGrid>
        {paging.pageArticles?.map((article, index) => <ArticleItem key={index} article={article} locale={locale} readMore={site.globals.readMoreSection.readMoreButtonTitle}/>)}
      </ArticleGrid>
      {paging.pages > 1 &&
        <PagingRow>
          {previousPage > 0 && (
            <PagingArrow to={'/' + locale + '/' + pageName + '/' + (category ? 'category/' + category.slug + '/' : '') + (previousPage > 1 ? previousPage + '/' : '')}>
              <img src={arrowIcon} alt="Back" />
            </PagingArrow>
          )}
          {[...Array(paging.pages)].map((e, i) => {
            return (
              <PagingItem
                key={'paging_' + i}
                disabled={i + 1 === paging.page}

                to={'/' + locale + '/' + pageName + '/' + (category ? 'category/' + category.slug + '/' : '') + ((i + 1) > 1 ? (i + 1) + '/' : '')}
              >
                {i + 1}
              </PagingItem>
            )
          })}
          {nextPage <= paging.pages && (
            <PagingArrow to={'/' + locale + '/' + pageName + '/' + (category ? 'category/' + category.slug + '/' : '') + nextPage + '/'} flip={1}>
              <img src={arrowIcon} alt="Back" />
            </PagingArrow>
          )}
        </PagingRow>
      }
    </Layout>
  );
}

export default BlogPage

const TitleRow = styled.div`
  display: flex;
  justify-content: ${props => props.spaced ? 'space-between' : 'flex-start'};
  align-items: center;
  width: 100%;
  margin-bottom: 48px;
`

const BlogTitle = styled(H2)`
  
`

const CategoryTitle = styled(H3)`
  width: 100%;
  text-align: left;
  margin-bottom: 48px;
  margin-top: -32px;
`

const AllPostsLink = styled(Link)`
  display: flex;
  white-space: nowrap;
  text-transform: uppercase;
  text-decoration: none;
  color: #000000;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  
  img {
    margin-left: 8px;
    transform: translate3d(0, 1px, 0);
    transition: transform 0.35s ease-in-out;
  }
  
  &:hover {
    img {
      transform: translate3d(80%, 1px, 0);
    }
  }
`

const ArticleGrid = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: self-start;
  flex-flow: row wrap;
`

const PagingRow = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`

const PagingArrow = styled(Link)`
  img {
    ${props => props.flip ? 'transform: rotate(270deg);' : 'transform: rotate(90deg);'}
  }
`

const PagingItem = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 41px;
  height: 41px;
  border-radius: 50%;
  text-decoration: none;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 140%;
  text-align: center;
  color: #000000;

  ${props => props.disabled ? 'pointer-events: none;' : ''}
  ${props => props.disabled ? 'background-color: #B0ECFB;' : ''}
`